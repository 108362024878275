<template>
  <div id="litesitespage">
    <Header />
    <WAppIcon link="https://wa.link/yftlly" />
    <div class="bodylanding gradientBK">
      <div class="bloqueuno">
        <div class="fila1-b1">
          <div class="col1-f1b1">
            <h5>Hazte notar donde tus clientes te están buscando</h5>
            <h1>Hacemos tu página web por <span>6 pagos de $1,199MXN</span></h1>
            <p class="le3d">¡Lista en 3 días!</p>
            <a href="#conoce" class="ti-a"
              ><div class="titut-la">Conoce los beneficios</div></a
            >

            <p>*Válido en tarjetas de crédito participantes</p>
          </div>
          <div class="col2-f1b1">
            <img src="../assets/img1land.png" alt="Una web para todos" />
          </div>
        </div>
      </div>
      <div class="bloquedos sectionb centert" id="conoce">
        <h2>Conoce los beneficios</h2>
        <p>
          En Legrafica, el dominio de tu sitio web te pertenece desde el
          principio, diseñado y optimizado para una buena experiencia de
          usuario. 
        </p>
      </div>
      <div class="bloquetres sectionb lefty">
        <div class="col1-b3">
          <img src="../assets/imgseo.png" alt="" />
        </div>
        <div class="col2-b3">
          <p class="idbene">01</p>
          <h2><span>Aparece en los </span>primeros lugares de búsqueda</h2>
          <p>
            Hemos creado la estructura perfecta entre contenido, usabilidad y
            diseño para hacer sitios web con buen posicionamiento SEO.
          </p>
        </div>
      </div>
      <div class="bloquecuatro sectionb rigty">
        <div class="col1-b4">
          <p class="idbene">02</p>
          <h2><span>Menú autoeditable</span> para servicios y restaurantes</h2>
          <p>
            Podrás cambiar precios, nombres y descripciones de tu producto o
            servicio, de manera rápida y fácil.
          </p>
        </div>
        <div class="col2-b4">
          <img src="../assets/imgmenu.png" alt="" />
        </div>
      </div>
      <div class="bloquecinco sectionb lefty">
        <div class="col1-b5">
          <img src="../assets/imgdesign.png" alt="" />
        </div>
        <div class="col2-b5">
          <p class="idbene">03</p>
          <h2><span>Diseño</span> responsivo</h2>
          <p>
            Tu sitio web podrá usarse tanto en computadores, celulares y
            tablets, con las medidas y usabilidad que cada pantalla requiere. 
          </p>
        </div>
      </div>
      <div class="bloqueseis sectionb">
        <div class="fila2-b1 pc centert">
          <div class="col-f2b1">
            <img src="../assets/sl1.svg" alt="" />
            <h6>Diseño de banner principal</h6>
            <p>
              Nuestros diseñadores se harán cargo de la imagen del sitio, por lo
              que no necesitas preocuparte por eso. 
            </p>
          </div>
          <div class="col-f2b1">
            <img src="../assets/sl2.svg" alt="" />
            <h6>Formulario de contacto</h6>
            <p>
              Integramos llamadas a la acción inmediatas para lograr mejores
              resultados.
            </p>
          </div>
          <div class="col-f2b1">
            <img src="../assets/sl3.svg" alt="" />
            <h6>Integración con Instagram</h6>
            <p>
              Presentamos tu red social más importante para tener al tanto a tu
              público de tu contenido diario.
            </p>
          </div>
          <div class="col-f2b1">
            <img src="../assets/sl4.svg" alt="" />
            <h6>Integración con WhatsApp</h6>
            <p>
              Dales la accesibilidad a tus clientes de comunicarse contigo por
              medios conocidos y personales.
            </p>
          </div>
        </div>
        <carousel
          :per-page="1"
          class="slideA mo"
          :mouse-drag="true"
          :navigation-enabled="true"
          :navigationNextLabel="nextB"
          :navigationPrevLabel="nextA"
          :loop="true"
          :paginationEnabled="false"
        >
          <slide>
            <div class="col-f2b1">
              <img src="../assets/sl1.svg" alt="" />
              <h6>Diseño de banner principal</h6>
              <p>
                Nuestros diseñadores se harán cargo de la imagen del sitio, por
                lo que no necesitas preocuparte por eso. 
              </p>
            </div>
          </slide>
          <slide>
            <div class="col-f2b1">
              <img src="../assets/sl2.svg" alt="" />
              <h6>Formulario de contacto</h6>
              <p>
                Integramos llamadas a la acción inmediatas para lograr mejores
                resultados.
              </p>
            </div>
          </slide>
          <slide>
            <div class="col-f2b1">
              <img src="../assets/sl3.svg" alt="" />
              <h6>Integración con Instagram</h6>
              <p>
                Presentamos tu red social más importante para tener al tanto a
                tu público de tu contenido diario.
              </p>
            </div>
          </slide>
          <slide>
            <div class="col-f2b1">
              <img src="../assets/sl4.svg" alt="" />
              <h6>Integración con WhatsApp</h6>
              <p>
                Dales la accesibilidad a tus clientes de comunicarse contigo por
                medios conocidos y personales.
              </p>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="bloquesiete sectionb">
        <div class="col1-b7">
          <p class="idbene">04</p>
          <h2><span>Optimizado para</span> Google</h2>
          <p>
            Cuidamos que tu página web cumpla con todos los requisitos de Google
            y este lista para empezar a aparecer en el top de búsquedas con
            campañas de pago.
          </p>
          <img src="../assets/opt_gl.png" alt="google" class="mo" />
          <img src="../assets/opt_gl_m.png" alt="google" class="pc" />
        </div>
        <div class="col2-b7">
          <p class="idbene">05</p>
          <h2>Mapa de ubicación de <span>Google Maps</span></h2>
          <p>
            Agregamos la ubicación de tu negocio con Google Maps para facilitar
            su búsqueda.
          </p>
          <img src="../assets/cel-1.png" alt="cel" class="w1" />
        </div>
      </div>
      <div class="bloqueocho sectionb">
        <!--<p class="idbene">06</p>-->
        <h2>
          Dominio .com y servidor <span>gratis</span> los primeros 6 meses.
        </h2>
      </div>
      <div class="bloquenueve sectionb">
        <h2>
          Campaña de Google Ads <span>GRATIS</span> al contratar en agosto.
        </h2>
        <p>
          Queremos que tu sitio llegue a su objetivo más rápido, por eso al
          contratar nuestros servicios en el mes de agosto, te ayudamos a poner
          en circulación una campaña de Google Ads.
        </p>
        <p class="white">
          * No incluye presupuesto para inversión en la plataforma.
        </p>
      </div>
      <div class="partners sectionb">
        <h2><span>Algunos de nuestros clientes</span></h2>

        <carousel
          :per-page="1"
          class="slideA mo"
          :mouse-drag="true"
          :navigation-enabled="true"
          :navigationNextLabel="nextB"
          :navigationPrevLabel="nextA"
          :loop="true"
          :paginationEnabled="false"
        >
          <slide>
            <div class="mosaic">
              <img src="../assets/partners/Agrofesa.png" alt="partners" />
              <img src="../assets/partners/Cedasa.png" alt="partners" />
              <img src="../assets/partners/BAMX.png" alt="partners" />
              <img src="../assets/partners/Circulo.png" alt="partners" />
              <img src="../assets/partners/Bioderma.png" alt="partners" />
              <img src="../assets/partners/Fanosa.png" alt="partners" />
            </div>
          </slide>
          <slide>
            <div class="mosaic">
              <img src="../assets/partners/Cimarrones.png" alt="partners" />
              <img src="../assets/partners/FA.png" alt="partners" />
              <img src="../assets/partners/Gynecore.png" alt="partners" />
              <img src="../assets/partners/HSB.png" alt="partners" />
              <img src="../assets/partners/Make.png" alt="partners" />
              <img src="../assets/partners/Pipeso.png" alt="partners" />
            </div>
          </slide>
          <slide>
            <div class="mosaic">
              <img src="../assets/partners/PorKilo.png" alt="partners" />
              <img src="../assets/partners/Sayab.png" alt="partners" />
              <img src="../assets/partners/SDN.png" alt="partners" />
              <img src="../assets/partners/Symborg.png" alt="partners" />
              <img src="../assets/partners/Virgo.png" alt="partners" />
              <img src="../assets/partners/VM.png" alt="partners" />
            </div>
          </slide>
        </carousel>

        <carousel
          :per-page="1"
          class="slideA pc"
          :mouse-drag="true"
          :navigation-enabled="true"
          :navigationNextLabel="nextB"
          :navigationPrevLabel="nextA"
          :loop="true"
          :paginationEnabled="false"
        >
          <slide class="slide-c">
            <div class="mosaic">
              <img src="../assets/partners/Agrofesa.png" alt="partners" />
              <img src="../assets/partners/Cedasa.png" alt="partners" />
              <img src="../assets/partners/BAMX.png" alt="partners" />
              <img src="../assets/partners/Circulo.png" alt="partners" />
            </div>
            <div class="mosaic">
              <img src="../assets/partners/Bioderma.png" alt="partners" />
              <img src="../assets/partners/Fanosa.png" alt="partners" />
              <img src="../assets/partners/Bistro.png" alt="partners" />
              <img src="../assets/partners/HSB.png" alt="partners" />
            </div>
          </slide>
          <slide class="slide-c">
            <div class="mosaic">
              <img src="../assets/partners/Cimarrones.png" alt="partners" />
              <img src="../assets/partners/FA.png" alt="partners" />
              <img src="../assets/partners/Gynecore.png" alt="partners" />
              <img src="../assets/partners/Make.png" alt="partners" />
            </div>
            <div class="mosaic">
              <img src="../assets/partners/Pipeso.png" alt="partners" />
              <img src="../assets/partners/PorKilo.png" alt="partners" />
              <img src="../assets/partners/Sayab.png" alt="partners" />
              <img src="../assets/partners/SDN.png" alt="partners" />
            </div>
          </slide>
          <slide class="slide-c">
            <div class="mosaic">
              <img src="../assets/partners/Symborg.png" alt="partners" />
              <img src="../assets/partners/Virgo.png" alt="partners" />
              <img src="../assets/partners/VM.png" alt="partners" />
            </div>
          </slide>
        </carousel>
      </div>
      <div class="bloquediez sectionb">
        <div class="col1-b4">
          <img src="../assets/img5land.png" alt="" />
        </div>
        <div class="col2-b4">
          <h2>
            <span>¿Empezamos?</span
            ><a
              href="https://api.whatsapp.com/send?phone=5216621715728&text=Me%20interesa%20tener%20un%20sitio%20web%20"
              target="_blank"
              rel="noopener noreferrer"
              >Haz click aquí</a
            >
          </h2>
          <p><a href="mailto:info@legrafica.mx">info@legrafica.mx</a></p>
          <p>
            <a href="tel:6621715728">(662) 171 5728</a> y
            <a href="tel:6622754218">(662) 275 4218</a>
          </p>
          <div class="soc-m-f">
            <a
              href="https://es-la.facebook.com/legrafica/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="../assets/fb-i.svg"
                alt="sm"
                class="icon"
                width="8"
                height="14"
              />
            </a>
            <a
              href="https://www.instagram.com/legrafica/?hl=es"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="../assets/ig-i.svg"
                alt="sm"
                class="icon"
                width="8"
                height="14"
              />
            </a>
            <a
              href="https://twitter.com/legrafica"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="../assets/tw-i.svg"
                alt="sm"
                class="icon"
                width="8"
                height="14"
              />
            </a>
            <a
              href="https://mx.linkedin.com/company/legrafica"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="../assets/li-i.svg"
                alt="sm"
                class="icon"
                width="8"
                height="14"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue";
import WAppIcon from "../components/on-site/WAppIcon";

export default {
  name: "LiteSites",
  data() {
    return {
      nextA: '<div class="prev-b"><</div>',
      nextB: '<div class="next-b">></div>',
    };
  },
  components: {
    WAppIcon,
    Header,
    Footer,
  },
  methods: {
    changeBackgroundPartners(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("gradientBK")[0];
      let divcont1 = document.getElementsByClassName("gradientBK")[1];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset <= 30) {
          divcont.style.backgroundColor = "#E4E4F9";
          /*divcont1.style.backgroundColor = "#E4E4F9";*/
        }
        if (offset >= 30) {
          divcont.style.backgroundColor = "#FFFFFF";
          /*divcont1.style.backgroundColor = "#FFFFFF";*/
        }
      } else if (min768.matches) {
        /**#e4e4f9 */
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          /*divcont1.style.backgroundColor = "#E4E4F9";*/
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          /*divcont1.style.backgroundColor = "#FFFFFF";*/
        }
      }
    },
  },
  mounted() {
    this.changeBackgroundPartners;
    window.addEventListener("scroll", this.changeBackgroundPartners);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Lite Sites - Legrafica";
      },
    },
  },
};
</script>

<style>
img {
  width: 100%;
} /*
.logo-cont img {
    width: 32.850241545893724VW !important;
    height: 9.420289855072465VW !important;
}
.menu-btn {
    width: 14.009661835748794VW !important;
    height: 14.009661835748794VW !important;
    border: 1px solid #ffffff !important;
    margin-top: -2vw;
}
.bar {
    transition: 0.5s;
    background-color: #ffffff !important;
}*/
.pc {
  display: none;
}
.mo {
  display: block;
}
.bodylanding {
  background-color: #e4e4f9;
}
.bloqueuno {
  background-image: url("../assets/Pleca_MOVIL.png");
  background-size: 100%;
  background-repeat: no-repeat;
  /*background-color: #e4e4f9;*/
  padding-top: 39.61352657004831vw;
  padding-left: 11.594202898550725vw;
}
.bloqueuno h5 {
  margin: 0;
  font-family: "Gramatika-Bold";
  font-size: 4.245vw;
  line-height: 6.012077294685991vw;
  width: 57vw;
  font-weight: 400;
  color: #443a4d;
}
.bloqueuno h1 {
  margin: 0;
  font-family: "Gramatika-Medium";
  font-size: 9.66183574879227vw;
  line-height: 11.719806763285025vw;
  width: 75.36231884057972vw;
  font-weight: 400;
  margin-top: 6vw;
  margin-bottom: 3.5vw;
  color: #443a4d;
}
.bloqueuno h1 span {
  color: #ef67c2;
}
.bloqueuno p {
  margin: 0;
  font-family: "Gramatika-Medium";
  font-size: 1.887vw;
  line-height: 3.012077294685991vw;
  width: 65vw;
  font-weight: 400;
  margin-top: 7.729vw;
  margin-bottom: 1.887vw;
  color: #443a4d;
}
.bloqueuno p.le3d {
  font-size: 4.831vw;
  line-height: 138.3%;
  font-weight: 500;
  margin: 0 0 3.979vw;
}
.ti-a{
  width: fit-content;
  display: flex;
}
.titut-la {
  width: 65.217vw !important;
  height: 14.251vw !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  border: 0.2vw solid black;
  color: black;
  font-weight: 600;
  transition: 0.5s;
}
.titut-la:hover {
  background-color: #851bff;
  color: white;
  border: 0.2vw solid #851bff;
}
.bloqueuno .titut-la h5 {
  width: auto;
  line-height: unset;
  /*height: 4vw;*/
}
.titut-la span {
  color: #ef67c2;
  font-family: "Gramatika-Bold";
}
.bloqueuno img {
  width: 95%;
  height: auto;
  margin-bottom: 10vw;
}
.bloquedos {
  margin: 0;
}
.bloquedos h2 {
  text-align: center;
  color: #ef67c2;
  font-family: "Gramatika-Regular";
  font-size: 6.763285024154589vw;
  margin: 0;
  margin-top: 5vw;
}
.bloquedos p {
  color: #000000;
  font-family: "Gramatika-Regular";
  font-size: 3.140096618357488vw;
  line-height: 5.159420289855072vw;
  text-align: center;
  /*width: 61.35265700483091VW;*/
  width: 60.75265700483091vw;
  margin: auto;
  margin-top: 9.420289855072465vw;
}
.col1-b3 {
  text-align: center;
  margin-top: 16.32367149758454vw;
}
.bloquetres img {
  width: 75.236vw;
  height: 54.347826086956516vw;
  margin-bottom: 4.1062801932367154vw;
}
.sectionb h2 {
  color: #987ef3;
  font-size: 6.280193236714976vw;
  font-family: "Gramatika-Regular";
  margin: 0 auto;
  text-align: center;
}
.sectionb h2 span {
  color: #ef67c2;
}
.sectionb p {
  font-size: 3.140096618357488vw;
  font-family: "Gramatika-Regular";
  text-align: center;
  margin: 0 auto;
  margin-top: 4.072463768115942vw;
}
.idbene {
  color: #b2acfc !important;
  font-size: 2.657004830917874vw !important;
  font-family: "IBMPlex-Regular" !important;
  margin: auto !important;
  width: 61vw;
  text-align: left !important;
  margin-top: auto !important;
}
.bloquecuatro {
  margin-top: 20.755vw;
  display: flex;
  flex-direction: column-reverse;
}
.bloquecuatro img {
  width: 81.64251207729468vw;
  height: 54.347826086956516vw;
  margin-bottom: 4.1062801932367154vw;
}
.bloquecuatro h2 {
  width: 59.17874396135265vw;
}
.bloquecuatro p {
  margin-top: 4.072463768115942vw;
  width: 66.90821256038647vw;
}
.bloquecuatro .idbene {
  color: #b2acfc !important;
  font-size: 2.657004830917874vw !important;
  font-family: "IBMPlex-Regular" !important;
  margin: auto !important;
  width: 53vw;
  text-align: left !important;
  margin-top: auto !important;
}
.col2-b4 {
  text-align: center;
  /*margin-top: 16.32367149758454VW;*/
}
.bloquecinco {
  margin-top: 22.22222222222222vw;
}
.col1-b5 {
  display: flex;
}
.col1-b5 img {
  width: 77.123vw;
  margin: auto;
}
.col2-b5 {
  width: 65.094vw;
  margin: 5.189vw auto 0;
}
.bloquecinco h2 {
  margin: 0;
}
.bloquecinco h2 span {
  color: #987ef3;
}
.bloquecinco .idbene {
  margin-left: 7vw !important;
}
.col-f2b1 img {
  width: 38.443vw;
  height: auto;
  margin: auto;
}
.col-f2b1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col-f2b1 h6 {
  font-family: "Gramatika-Medium";
  font-size: 6.132vw;
  line-height: 150.8%;
  text-align: center;
  color: #ef67c2;
  margin: 11.321vw 0 0;
  width: 54vw;
}
.col-f2b1 p {
  width: 59.434vw;
  margin: 4.953vw auto 0;
}
.bloqueseis {
  margin-top: 21.698vw;
}
.prev-b,
.next-b {
  width: 7.783vw;
  height: 7.783vw;
  border-radius: 11.792vw;
  border: 0.354vw solid #ef67c2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.123vw;
  line-height: 3vw;
  margin: auto;
  color: #ef67c2;
}
.bloquesiete {
  display: flex;
  flex-direction: column;
}
.bloquesiete .col1-b7 {
  padding-top: 13.679vw;
  /*width: 77.83vw;*/
  width: 100%;
  margin: auto;
  background-color: #f8f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col1-b7 p {
  width: 66.274vw;
}
.bloquesiete img {
  width: 89.151vw;
  margin: 8.2vw auto 0;
}
.bloquesiete .col2-b7 {
  padding-top: 13.679vw;
  /*width: 61.858vw;*/
  width: 100%;
  margin: 4.868vw auto 0;
  background-color: #f8f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col2-b7 p {
  width: 56.368vw;
}
.col2-b7 h2 {
  width: 56.368vw;
}
.bloquesiete img.w1 {
  width: 100%;
  margin: 0;
}
.bloqueocho h2 {
  padding: 33.491vw 0;
  width: 52.377vw;
}
.bloquenueve {
  background-image: url("../assets/cel-2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 25.943vw 0 99.104vw;
    display: none;
}
.bloquenueve h2 {
  margin: 0;
  font-family: "Gramatika-Medium";
  font-size: 7.075vw;
  line-height: 150.8%;
  text-align: center;
  color: #ffffff;
  width: 65.094vw;
  margin: auto;
}
.bloquenueve h2 span {
  color: #851bff;
}
.bloquenueve p {
  width: 59.547vw;
  margin-top: 17.072464vw;
}
.bloquenueve p.white {
  width: auto;
  color: white;
  padding: 0;
}
.bloquediez {
  padding-top: 20.047vw;
}
.bloquediez .col2-b4 {
  width: 61.726vw;
  margin: 16.981vw auto;
}
.bloquediez .col2-b4 h2 {
  width: 53.726vw;
  margin: 0 auto 14.858vw;
}
.bloquediez .col2-b4 h2 a {
  color: #987ef3;
}
/***
    color: #987ef3; */
.bloquediez .col2-b4 p {
  font-family: "IBM Plex Mono";
  margin: 0;
  color: #443a4d;
}
.bloquediez .col2-b4 p a {
  color: #443a4d;
}
.col2-b4 .soc-m-f {
  padding-top: 13.285vw;
  width: 100%;
}
.col2-b4 .icon {
  filter: invert(65%) sepia(41%) saturate(7045%) hue-rotate(221deg)
    brightness(84%) contrast(91%);
}
.partners {
  background-color: #ef67c2;
  padding: 18.868vw 0 20.755vw;
}
.partners h2 {
  width: 54.481vw;
  margin-bottom: 10.313vw;
}
.partners h2 span {
  color: #ffb6ea;
}
.partners .mosaic img {
  width: auto;
  height: 14.858vw;
}
.partners .mosaic {
  width: 71vw;
  height: 69.811vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  margin: 16.509vw auto 0;
}
@media (min-width: 768px) {
  .pc {
    display: block;
  }
  .mo {
    display: none;
  }
  .bloqueuno {
    background-image: url("../assets/Pleca_PC.png");
    background-size: 75%;
    background-position-x: right;
    padding-top: 4.583vw;
    padding-bottom: 11vw;
    padding-left: 8.75vw;
  }
  .fila1-b1 {
    display: flex;
  }
  .bloqueuno h5 {
    font-size: 1.302vw;
    line-height: 138.3%;
  }
  .bloqueuno h1 {
    font-size: 4.583vw;
    line-height: 121.3%;
    margin-top: 1.302vw;
    margin-bottom: 2.917vw;
    width: 100%;
  }
  .bloqueuno p {
    font-size: 0.781vw;
    margin-top: 2.76vw;
  }
  .col1-f1b1 {
    width: 41.031vw;
    margin-top: 10vw;
  }
  .bloqueuno p.le3d {
    font-size: 2.083vw;
    line-height: 138.3%;
    font-weight: 500;
    margin: 0 0 2.917vw;
  }
  .titut-la {
    width: 21.979vw !important;
    height: 5.417vw !important;
    position: relative;
font-size: 1.250vw;
    left: 0;
  }
  .bloqueuno .titut-la h5 {
    font-size: 2.083vw !important;
    line-height: unset !important;
    width: auto;
    display: flex;
    /* height: 2.083vw;*/
  }
  .bloqueuno img {
    width: 47.24vw;
    margin-bottom: auto;
  }
  .sectionb h2 {
    font-size: 3.646vw;
    line-height: 3.646vw;
    text-align: left;
  }
  .sectionb p {
    font-size: 0.938vw;
    line-height: 179.8%;
    margin-top: 2.5vw;
    text-align: left;
    margin-left: 0;
  }
  .bloquetres {
    margin-top: 16.32367149758454vw;
  }
  .col1-b3 {
    text-align: center;
    margin-top: 0;
    margin-right: 5.938vw;
  }
  .bloquedos h2 {
    color: #ef67c2;
  }
  .bloquedos p {
    width: 34.479vw;
  }
  .sectionb.lefty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sectionb.rigty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .bloquetres img {
    width: 48.229vw;
    height: auto;
    margin-bottom: 0;
  }
  .col2-b3 {
    width: 28.74vw;
  }
  .idbene,
  .bloquecuatro .idbene {
    font-size: 0.729vw !important;
  }
  .bloquecinco .idbene {
    margin-left: 0 !important;
  }
  .centert h2 {
    text-align: center;
  }
  .centert p {
    text-align: center;
    margin-left: auto;
  }
  .bloquecuatro img {
    width: 39.219vw;
    height: auto;
  }
  .col1-b4 {
    width: 32.969vw;
  }
  .bloquecuatro h2 {
    width: auto;
  }
  .bloquecuatro p {
    width: 21.875vw;
  }
  .col2-b4 {
    margin-left: 7.135vw;
  }
  .bloquecuatro,
  .bloquecinco {
    margin-top: 14.844vw;
  }
  .col1-b5 img {
    width: 53.438vw;
    margin: auto;
  }
  .col2-b5 {
    width: 18.729vw;
    margin: 0 0 0 4.323vw;
  }
  .fila2-b1 {
    display: flex;
    justify-content: space-between;
    width: 81.875vw;
    margin: auto auto 18.594vw;
  }
  .col-f2b1 img {
    width: 8.49vw;
  }
  .col-f2b1 h6 {
    width: 11.719vw;
    font-size: 1.354vw;
    margin-top: 4.01vw;
  }
  .col-f2b1 p {
    width: auto;
  }
  .col-f2b1 {
    width: 17.3vw;
  }
  .two-blocks {
    display: flex;
  }
  .bloquesiete img {
    width: 100%;
    margin: 0 auto 0;
    position: relative;
    top: -5vw;
  }
  .bloquesiete .col1-b7 img {
    top: -8vw;
  }
  .bloquesiete .col1-b7 {
    width: 39.844vw;
    height: 43vw;
    overflow: hidden;
    padding-top: 6.094vw;
    margin: 0;
    padding-left: 5.573vw;
  }
  .bloquesiete .col2-b7 {
    width: 39.844vw;
    height: 43vw;
    overflow: hidden;
    padding-top: 6.094vw;
    padding-left: 5.573vw;
    margin: 0;
  }

  .bloquesiete h2 {
    width: 24.479vw;
    margin-left: 0;
  }
  .col1-b7 p,
  .col2-b7 p {
    width: 24.274vw;
  }
  .bloquesiete {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .bloquesiete .idbene {
    width: auto;
    margin-left: 0vw !important;
  }
  .bloqueocho h2 {
    padding: 20.781vw 0;
    width: 52.377vw;
    text-align: center !important;
  }
  .bloquenueve {
    background-image: url("../assets/cel-2-pc.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 14.01vw 0 0 0;
    display: none;
  }
  .bloquenueve h2 {
    width: 35.896vw;
    margin: 0;
    font-family: "Gramatika-Bold";
    padding: 0 0 0 58.906vw;
  }
  .bloquenueve p {
    width: 22.547vw;
    padding: 0 0 10.104vw 58.906vw;
  }
  .bloquenueve p.white {
    width: auto;
    color: white;
    padding: 0 0 1.104vw 1.906vw;
  }
  .partners .mosaic img {
    width: auto;
    height: 5.858vw;
  }
  .slide-c {
    height: 22vw;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    width: 67.188vw;
    justify-content: space-between;
  }
  .partners .mosaic {
    width: 67.188vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 0;
    justify-content: space-between;
  }
  .prev-b,
  .next-b {
    width: 4.635vw;
    height: 4.635vw;
    border-radius: 11.792vw;
    border: 0.14vw solid #ef67c2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.423vw;
    line-height: 3vw;
    margin: auto;
    color: #ef67c2;
  }
  .bloquediez {
    padding-top: 9.375vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bloquediez .col2-b4 {
    width: 24.813vw;
    margin: 0 0 0 11.354vw;
  }
  .bloquediez .col1-b4 {
    width: 40.885vw;
  }
  .bloquediez .col2-b4 h2 {
    width: auto;
    margin: 0 auto 5.858vw;
    text-align: center;
  }
  .col2-b4 .soc-m-f {
    padding-top: 4.167vw;
    width: 7.552vw;
  }
}
</style>